import {
  type SanityHomePage,
  type SanityPage,
} from '@data/sanity/queries/types/page'
import { type SanitySiteFragment } from '@data/sanity/queries/types/site'
import { type WebPageSchema, type WebSiteSchema } from './types'

export const getWebSiteSchema = (site: SanitySiteFragment) => {
  const organization = site.schemaSettings?.organization
  const webSiteSchema: WebSiteSchema = {
    '@type': 'WebSite',
    name: organization?.name,
    url: organization?.url,
  }

  if (organization) {
    webSiteSchema.publisher = {
      '@type': 'Organization',
      name: organization?.name,
      url: organization?.url,
    }
  }

  return JSON.stringify(webSiteSchema)
}

export const getWebPageSchema = (
  page: SanityPage | SanityHomePage,
  site: SanitySiteFragment
) => {
  const organization = site.schemaSettings?.organization
  const metaTitle = page.seo?.metaTitle ?? site.seoSettings?.metaTitle
  const metaDescription = page.seo?.metaDesc ?? site.seoSettings?.metaDesc

  const pageType =
    'webPageSchema' in page ? page.webPageSchema?.pageType : undefined
  const webPageSchema: WebPageSchema = {
    '@type': pageType ?? 'WebPage',
    name: metaTitle,
    description: metaDescription,
  }

  if (organization) {
    webPageSchema.publisher = {
      '@type': 'Organization',
      name: organization?.name,
      url: organization?.url,
    }
  }

  return JSON.stringify(webPageSchema)
}
