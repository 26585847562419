import { type SanityPage } from '@data/sanity/queries/types/page'

import { type SanityBlock } from '@data/sanity/queries/types/blocks'
import { type SanityComplexPortableText } from '@data/sanity/queries/types/content'
import { type SanityModule } from '@data/sanity/queries/types/modules'
import { type SanityMuxVideoAsset } from '@data/sanity/queries/types/video'
import { type VideoObjectSchema } from './types'
import { getMuxVideoThumbnailUrl } from '@lib/video'

/**
 * Converts duration in seconds into ISO 8601 duration format.
 */
const getDurationInIsoFormat = (duration: number) => {
  const hours = Math.floor(duration / 3600)
  const minutes = Math.floor((duration % 3600) / 60)
  const seconds = Math.floor(duration % 60)

  if (!hours && !minutes && !seconds) {
    return 'PT0S'
  }

  return ['PT', `${hours}H`, `${minutes}M`, `${seconds}S`]
    .filter(Boolean)
    .join('')
}

/**
 * Gets video structured data from Mux asset.
 */
const getVideoObjectSchemaFromMuxAsset = (asset: SanityMuxVideoAsset) => {
  const videoObjectSchema: VideoObjectSchema = {
    '@type': 'VideoObject',
    name: asset.filename ?? asset.assetId.substring(0, 12),
    thumbnailUrl: getMuxVideoThumbnailUrl(asset.playbackId, {
      format: 'jpg',
      fitMode: 'preserve',
      width: 640,
      time: asset.thumbTime,
    }),
    uploadDate: asset._createdAt,
  }

  if (asset.data.duration) {
    videoObjectSchema.duration = getDurationInIsoFormat(asset.data.duration)
  }

  return videoObjectSchema
}

const getVideoObjectSchemasFromPortableText = (
  portableText?: SanityComplexPortableText,
) => {
  let videoObjectSchemas: VideoObjectSchema[] = []

  portableText?.forEach((contentBlock) => {
    if (!contentBlock) {
      return
    }

    // Video embed freeform block
    if (contentBlock._type === 'videoEmbed') {
      if (contentBlock.type === 'mux' && contentBlock.muxVideo?.asset) {
        videoObjectSchemas = [
          ...videoObjectSchemas,
          getVideoObjectSchemaFromMuxAsset(contentBlock.muxVideo.asset),
        ]
      }
    }
  })

  return videoObjectSchemas
}

const getVideoObjectSchemasFromBlock = (block: SanityBlock) => {
  let videoObjectSchemas: VideoObjectSchema[] = []

  // Predefined grid block - recursively travel its sub-blocks
  if (block._type === 'predefinedContentBlock') {
    block.contentBlocks.forEach((subBlock) => {
      if (!subBlock) {
        return
      }

      videoObjectSchemas = [
        ...videoObjectSchemas,

        ...getVideoObjectSchemasFromBlock(subBlock),
      ]
    })
  }

  // Video grid block
  if (block._type === 'video') {
    if (block.type === 'mux' && block.muxVideo?.asset) {
      videoObjectSchemas = [
        ...videoObjectSchemas,
        getVideoObjectSchemaFromMuxAsset(block.muxVideo.asset),
      ]
    }
  }

  // Freeform grid block
  if (block._type === 'freeform') {
    videoObjectSchemas = [
      ...videoObjectSchemas,
      ...getVideoObjectSchemasFromPortableText(block.content),
    ]
  }

  return videoObjectSchemas
}

const getVideoObjectSchemasFromModule = (module: SanityModule) => {
  let videoObjectSchemas: VideoObjectSchema[] = []

  // Predefined module - recursively travel its sub-modules
  if (module._type === 'predefinedPageModule') {
    module.pageModules.forEach((subModule) => {
      if (!subModule) {
        return
      }

      videoObjectSchemas = [
        ...videoObjectSchemas,

        ...getVideoObjectSchemasFromModule(subModule),
      ]
    })
  }

  // Video module
  if (module._type === 'video') {
    if (module.type === 'mux' && module.muxVideo?.asset) {
      videoObjectSchemas = [
        ...videoObjectSchemas,
        getVideoObjectSchemaFromMuxAsset(module.muxVideo.asset),
      ]
    }
  }

  // Grid module
  if (module._type === 'grid') {
    module.columns?.forEach((column) => {
      column?.blocks?.forEach((block) => {
        if (!block) {
          return
        }

        videoObjectSchemas = [
          ...videoObjectSchemas,
          ...getVideoObjectSchemasFromBlock(block),
        ]
      })
    })
  }

  // Blog post body module
  if (module._type === 'blogPostBody') {
    videoObjectSchemas = [
      ...videoObjectSchemas,
      ...getVideoObjectSchemasFromPortableText(module.content),
    ]
  }

  return videoObjectSchemas
}

export const getVideoObjectSchemas = (page: SanityPage) => {
  let videoObjectSchemas: VideoObjectSchema[] = []

  page.modules?.forEach((module) => {
    if (!module) {
      return
    }

    videoObjectSchemas = [
      ...videoObjectSchemas,
      ...getVideoObjectSchemasFromModule(module),
    ]
  })

  return videoObjectSchemas.map((videoObjectSchema) =>
    JSON.stringify(videoObjectSchema),
  )
}
