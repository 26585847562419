import { type SanitySchemaSettingsOrganization } from '@data/sanity/queries/types/site'
import { type OrganizationSchema, type PostalAddressSchema } from './types'
import { getSanityImageUrl } from '@lib/image'

export const getOrganizationSchema = (
  organization?: SanitySchemaSettingsOrganization
) => {
  const imageUrl = getSanityImageUrl(organization?.logo)
  const organizationSchema: OrganizationSchema = {
    '@type': 'Organization',
    name: organization?.name,
    url: organization?.url,
    logo: imageUrl,
    image: imageUrl,
    telephone: organization?.phone,
    email: organization?.email,
    sameAs: organization?.sameAsUrls,
  }
  const addresses = organization?.addresses
    ?.filter((address) => !!address.country)
    ?.map(
      (address) =>
        ({
          '@type': 'PostalAddress',
          addressCountry: address.country,
          addressLocality: address.locality,
          addressRegion: address.region,
          postOfficeBoxNumber: address.postOfficeBoxNumber,
          postalCode: address.postalCode,
          streetAddress: address.streetAddress,
        } satisfies PostalAddressSchema)
    ) as PostalAddressSchema[] | undefined

  if (addresses && addresses.length > 0) {
    organizationSchema.address = addresses
  }

  if (organization?.phone || organization?.email) {
    organizationSchema.contactPoint = [
      {
        '@type': 'ContactPoint',
        telephone: organization?.phone,
        email: organization?.email,
      },
    ]
  }

  return JSON.stringify(organizationSchema)
}
